/* eslint-disable no-undef*/
/* eslint-disable max-lines-per-function*/
/* eslint-disable no-unused-vars*/
// @ts-check

import './vendor/swts.js';
import { checkCookie, setCookie, getCookie } from './setCookie';
import { langConfig } from './config/langConfig';

// get langCode from html lang attribute or set default
export const langCode = document.documentElement.lang || 'de';

export const getlangConfig = (/** @type {string} */ key, lang=langCode, theLangconfig=langConfig) => {
    return theLangconfig[lang] ? theLangconfig[lang][key] : null;
};

// create a iffee for the swts script
(function() {
    if (checkCookie('SWTSdisable')) {
        console.warn('t_swts.js: swts is disabled');
        return;
    }
    if (window.location.hostname != getlangConfig('siteid')) {
        console.warn('t_swts.js: swts is only available on the main domain');
        return;
    }
    // @ts-ignore
    const swtsTracker = Swts.getTracker('/swts/', 1);
    swtsTracker.trackPageView();
    swtsTracker.enableLinkTracking();
})();

